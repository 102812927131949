const Logo = ({ className = "" }) => {
  return (
    <div className="">
      <svg
        className={`
        [&_.intelliverse:fill-[#eeeeec]
        [&_.line]:fill-[#111110] 
        [&_.star]:fill-[#111110] 
        [&_.circle]:fill-[#111110] 
        dark:[&_.intelliverse]:fill-[#eeeeec]
        dark:[&_.line]:fill-accent
        dark:[&_.star]:fill-[#fcda69] 
        dark:[&_.circle]:fill-accent

        w-48
        ${className}`}
        id="logo-svg"
        data-name="Logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 181.54 50.49"
      >
        <g>
          <g id="stars">
            <path
              className="star"
              d="M29.83,33.14c2.33-.55,2.77-.99,3.32-3.32.55,2.33.99,2.77,3.32,3.32-2.33.55-2.77.99-3.32,3.32-.55-2.33-.99-2.77-3.32-3.32Z"
            />
            <path
              className="star"
              d="M14.04,17.35c2.33-.55,2.77-.99,3.32-3.32.55,2.33.99,2.77,3.32,3.32-2.33.55-2.77.99-3.32,3.32-.55-2.33-.99-2.77-3.32-3.32Z"
            />
            <path
              className="star"
              d="M14.2,25.24c7.76-1.82,9.23-3.3,11.06-11.06,1.82,7.76,3.3,9.23,11.06,11.06-7.75,1.82-9.23,3.3-11.06,11.06-1.82-7.75-3.3-9.23-11.06-11.06Z"
            />
          </g>
          <g>
            <path
              className="line"
              d="M20.82,49.26c-.18.45-.66.72-1.15.6-1.25-.29-2.49-.67-3.68-1.14-3.09-1.22-5.86-3-8.23-5.28-.4-.38-.41-1.01-.03-1.41.38-.4,1.01-.41,1.41-.03,2.18,2.1,4.73,3.74,7.58,4.86,1.1.43,2.24.79,3.4,1.05.54.12.87.66.75,1.19-.01.05-.03.1-.04.14Z"
            />
            <path
              className="line"
              d="M48.74,34.51c-2.43,6.15-7.09,11.03-13.12,13.75-.5.23-1.09,0-1.32-.5-.23-.5,0-1.09.5-1.32,5.56-2.5,9.85-7,12.09-12.66s2.17-11.94-.21-17.58c-.21-.51.02-1.09.53-1.31.51-.21,1.09.02,1.31.53,2.59,6.12,2.67,12.89.23,19.08Z"
            />
            <path
              className="line"
              d="M40.98,6.4c-.03.08-.08.17-.14.24-.34.44-.96.52-1.4.18-1.72-1.32-3.62-2.4-5.66-3.21C21.86-1.09,8.33,4.79,3.63,16.71c-2.26,5.74-2.17,12.02.26,17.68.22.51-.02,1.09-.52,1.31-.51.22-1.09-.02-1.31-.52-2.64-6.15-2.74-12.97-.28-19.2C6.88,3.03,21.57-3.35,34.52,1.76c2.21.87,4.28,2.04,6.15,3.48.36.28.48.75.32,1.15Z"
            />
            <path
              className="line"
              d="M32.41,49.04c-.11.28-.35.51-.67.6-2.49.67-5.07.94-7.64.83-.55-.03-.97-.49-.95-1.04.02-.55.49-.97,1.04-.95,2.37.11,4.74-.15,7.04-.76.53-.14,1.08.17,1.22.71.06.21.04.43-.04.62Z"
            />
            <path
              className="circle"
              d="M5.97,37.36c-.93-.37-1.97.09-2.34,1.02s.09,1.97,1.02,2.34,1.97-.09,2.34-1.02-.09-1.97-1.02-2.34Z"
            />
            <circle className="circle" cx="44.46" cy="10.44" r="3.26" />
          </g>
          <g>
            <path
              className="intelliverse"
              d="M57.28,20.1c0-.58.48-1.06,1.06-1.06s1.06.48,1.06,1.06v10.42c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06v-10.42Z"
            />
            <path
              className="intelliverse"
              d="M71.84,20.1c0-.58.48-1.06,1.06-1.06s1.06.48,1.06,1.06v8.19c0,2-1.5,3.38-3.4,3.38s-3.4-1.38-3.4-3.38v-5.96c0-.79-.58-1.35-1.29-1.35s-1.29.56-1.29,1.35v8.19c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06v-8.19c0-2,1.5-3.38,3.4-3.38s3.4,1.38,3.4,3.38v5.96c0,.79.58,1.34,1.29,1.34s1.29-.56,1.29-1.34v-8.19Z"
            />
            <path
              className="intelliverse"
              d="M80.39,21.24h-3.21c-.56,0-1.02-.46-1.02-1.02s.46-1.02,1.02-1.02h8.53c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-3.21v9.28c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06v-9.28Z"
            />
            <path
              className="intelliverse"
              d="M90,31.42c-.58,0-1.06-.48-1.06-1.06v-10.11c0-.58.48-1.06,1.06-1.06h6.09c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-5.03v3.06h4.46c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-4.46v3.06h5.03c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-6.09Z"
            />
            <path
              className="intelliverse"
              d="M100.76,31.42c-.58,0-1.06-.48-1.06-1.06v-10.26c0-.58.48-1.06,1.06-1.06s1.06.48,1.06,1.06v9.28h4.75c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-5.8Z"
            />
            <path
              className="intelliverse"
              d="M110.75,31.42c-.58,0-1.06-.48-1.06-1.06v-10.26c0-.58.48-1.06,1.06-1.06s1.06.48,1.06,1.06v9.28h4.75c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-5.8Z"
            />
            <path
              className="intelliverse"
              d="M119.68,20.1c0-.58.48-1.06,1.06-1.06s1.06.48,1.06,1.06v10.42c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06v-10.42Z"
            />
            <path
              className="intelliverse"
              d="M124.29,20.51c-.23-.54.04-1.15.58-1.38.52-.23,1.15.02,1.38.56l3.46,8.45,3.46-8.45c.23-.54.86-.79,1.38-.56.54.23.81.85.58,1.38l-4.46,10.53c-.17.4-.56.63-.96.63s-.79-.23-.96-.63l-4.46-10.53Z"
            />
            <path
              className="intelliverse"
              d="M138.69,31.42c-.58,0-1.06-.48-1.06-1.06v-10.11c0-.58.48-1.06,1.06-1.06h6.09c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-5.04v3.06h4.46c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-4.46v3.06h5.04c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-6.09Z"
            />
            <path
              className="intelliverse"
              d="M151.89,26.06c-.17-.19-.29-.44-.29-.71,0-.58.48-1.06,1.06-1.06h2.17c.94,0,1.54-.67,1.54-1.52s-.6-1.54-1.54-1.54h-4.32v9.28c0,.58-.48,1.06-1.06,1.06s-1.06-.48-1.06-1.06v-10.26c0-.58.48-1.06,1.06-1.06h5.4c2.1,0,3.63,1.59,3.63,3.57s-1.48,3.52-3.56,3.54l3.19,3.52c.38.42.37,1.1-.08,1.48-.42.38-1.09.35-1.48-.08l-4.67-5.17Z"
            />
            <path
              className="intelliverse"
              d="M162.38,31.42c-.56,0-1.02-.48-1.02-1.04s.46-1,1.02-1h4.55c1,0,1.63-.67,1.63-1.52s-.63-1.54-1.63-1.54h-2.34c-2.21,0-3.71-1.58-3.71-3.56s1.5-3.57,3.71-3.57h4.59c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-4.55c-1,0-1.63.69-1.63,1.54s.63,1.52,1.63,1.52h2.42c2.13,0,3.63,1.59,3.63,3.57s-1.5,3.56-3.71,3.56h-4.59Z"
            />
            <path
              className="intelliverse"
              d="M174.43,31.42c-.58,0-1.06-.48-1.06-1.06v-10.11c0-.58.48-1.06,1.06-1.06h6.09c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-5.04v3.06h4.46c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-4.46v3.06h5.04c.56,0,1.02.46,1.02,1.02s-.46,1.02-1.02,1.02h-6.09Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
export default Logo;

export const Stars = ({ className = "" }) => {
  return (
    <svg
      id="stars"
      className={`fill-accent stroke-none ${className}`}
      data-name="stars"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.44 22.44"
    >
      <g>
        <path
          className="star"
          d="M15.79,19.12c2.33-.55,2.77-.99,3.32-3.32.55,2.33.99,2.77,3.32,3.32-2.33.55-2.77.99-3.32,3.32-.55-2.33-.99-2.77-3.32-3.32Z"
        />
        <path
          className="star"
          d="M0,3.32c2.33-.55,2.77-.99,3.32-3.32.55,2.33.99,2.77,3.32,3.32-2.33.55-2.77.99-3.32,3.32-.55-2.33-.99-2.77-3.32-3.32Z"
        />
        <path
          className="star"
          d="M.16,11.22C7.92,9.39,9.39,7.92,11.22.16c1.82,7.76,3.3,9.23,11.06,11.06-7.75,1.82-9.23,3.3-11.06,11.06C9.39,14.52,7.92,13.04.16,11.22Z"
        />
      </g>
    </svg>
  );
};

export const ICPTGSymbol = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 285.1 284.97"
      className={`stroke-none fill-black ${className} `}
    >
      <g>
        <g>
          <path d="M168.38,187.06c13.15-3.09,15.65-5.6,18.74-18.74,3.09,13.15,5.6,15.65,18.74,18.74-13.14,3.09-15.65,5.6-18.74,18.74-3.09-13.14-5.6-15.65-18.74-18.74Z" />
          <path d="M79.24,97.91c13.15-3.09,15.65-5.6,18.74-18.74,3.09,13.15,5.6,15.65,18.74,18.74-13.14,3.09-15.65,5.6-18.74,18.74-3.09-13.14-5.6-15.65-18.74-18.74Z" />
          <path d="M80.14,142.49c43.78-10.3,52.12-18.64,62.42-62.42,10.29,43.78,18.64,52.12,62.41,62.42-43.77,10.3-52.12,18.64-62.41,62.41-10.3-43.76-18.64-52.11-62.42-62.41Z" />
        </g>
        <g>
          <path d="M117.53,278.05c-1,2.54-3.74,4.04-6.49,3.41-7.07-1.62-14.07-3.79-20.79-6.44-17.45-6.88-33.08-16.91-46.46-29.81-2.24-2.16-2.3-5.72-.15-7.95,2.16-2.24,5.71-2.3,7.95-.15,12.32,11.87,26.71,21.1,42.78,27.44,6.2,2.45,12.65,4.45,19.18,5.94,3.03.69,4.92,3.71,4.22,6.74-.07.28-.15.55-.25.81Z" />
          <path d="M275.09,194.78c-13.69,34.71-40,62.27-74.08,77.6-2.83,1.27-6.16.01-7.43-2.82-1.28-2.83-.01-6.16,2.82-7.43,31.39-14.12,55.62-39.5,68.23-71.47,12.7-32.18,12.27-67.41-1.2-99.2-1.21-2.86.12-6.16,2.98-7.37,2.86-1.21,6.16.12,7.37,2.98,14.63,34.52,15.09,72.77,1.31,107.72Z" />
          <path d="M231.32,36.1c-.19.48-.45.94-.78,1.37-1.9,2.46-5.43,2.92-7.89,1.02-9.69-7.46-20.44-13.55-31.95-18.09C123.39-6.15,47.03,27.02,20.47,94.34c-12.78,32.38-12.26,67.83,1.45,99.8,1.23,2.85-.1,6.16-2.95,7.38-2.85,1.22-6.16-.1-7.38-2.95-14.89-34.72-15.45-73.21-1.58-108.36C38.84,17.12,121.75-18.89,194.83,9.94c12.49,4.93,24.16,11.54,34.69,19.64,2.03,1.57,2.7,4.25,1.8,6.52Z" />
          <path d="M182.93,276.78c-.63,1.6-1.99,2.89-3.78,3.37-14.08,3.76-28.59,5.33-43.14,4.66-3.1-.14-5.5-2.77-5.36-5.87.14-3.1,2.77-5.5,5.87-5.36,13.4.61,26.76-.83,39.73-4.3,3-.8,6.08.98,6.88,3.98.32,1.21.23,2.43-.2,3.51Z" />
          <path d="M33.7,210.85c-5.23-2.06-11.14.5-13.2,5.73-2.06,5.23.5,11.14,5.73,13.2,5.23,2.06,11.14-.5,13.2-5.73,2.06-5.23-.5-11.14-5.73-13.2Z" />
          <circle cx="250.93" cy="58.91" r="18.38" />
        </g>
      </g>
    </svg>
  );
};
