"use client"

import { Moon, Sun } from "lucide-react"
import { useTheme } from "next-themes"
import { Button } from "@/components/ui/button"

const ModeSwitcher = () => {
  const { setTheme , theme } = useTheme()

  return (
    <>
        <Button
        variant="outline"
        onClick={() => theme == 'dark' ? setTheme("light") : setTheme("dark")}
        >
          <Sun className="h-4 w-4 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-4 w-4 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
        </Button>
    </>
  )
}

export default ModeSwitcher
